<template>
    <div class="form-wrap">
        <div class="operate-btns">
            <div class="btn buyup" @click="openForm(1)">{{$t('SecondContract.BuyUp')}}</div>
            <div class="btn buydown" @click="openForm(-1)">{{$t('SecondContract.BuyDown')}}</div>
        </div>
        <div v-if="showModal" class="mask" @click.stop="showModal = false">
            <div id='formWrap' class="box animate__animated animate__fadeInUp" @click.stop="showModal = true">
                <div class="title">{{$t('SecondContract.Buy')}} {{curCoin.name}}</div>
                <div class="order-type">
                    <div class="label">{{$t('PerpetualContract.OrderType')}}</div>
                    <div class="type-list">
                        <div v-for="(item,index) in typeList" :key="index"
                            :class="['type-item', typeIndex == index ? 'type-item-active': '']"
                            @click="changeTypeIndex(index)">
                            {{item}}
                        </div>
                    </div>
                </div>
                <div v-if="typeIndex == 1" class="date-item">
                    <!-- <div class="label">{{$t('SecondContract.ChooseTime')}}</div> -->
                    <el-time-select
                      v-model="date"
                      :picker-options="dateOptions"
                      :placeholder="$t('SecondContract.ChooseTime')">
                    </el-time-select>
                </div>
                <div class="operate-item">
                    <div class="label">{{$t('SecondContract.CurrentPrice')}} ({{curCoin.name}})</div>
                    <div class="num">{{parseFloat(curCoin.price.close)}}</div>
                </div>
                <div class="operate-item">
                    <div class="label">{{$t('SecondContract.OpeningQuantity')}} (USDT)</div>
                    <el-input class="quantity-input" v-model="num" type="number" :placeholder="$t('SecondContract.EnterOpeningQuantity')"></el-input>
                </div>
                <div class="opening-options">
                    <div class="opening-quantity">
                        <div class="label">{{$t('SecondContract.SelectQuantity')}}</div>
                        <div class="select-box">
                            <div class="list">
                                <div v-for="(item,index) in openList" 
                                :key="index"
                                :class="['item', curIndex1 == index ? 'active' : '']"
                                @click="changeCurIndex1(index)">
                                    {{item}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="opening-time">
                        <div class="label">{{$t('SecondContract.ChooseDuration')}}</div>
                        <div class="list">
                            <div v-for="(item,index) in timeList" 
                            :key="index"
                            :class="['item', curIndex2 == index ? 'active' : '']"
                            @click="changeCurIndex2(index)">
                                <div class="time">{{item.minute}}S</div>
                                <div class="profitability">({{$t('SecondContract.Profitability')}} {{item.profit_rate}}%)</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="info-list">
                    <div class="info-item">
                        <div class="label">{{$t('SecondContract.Balance')}}</div>
                        <div class="value">{{balance}}USDT</div>
                    </div>
                    <div class="info-item">
                        <div class="label">{{$t('SecondContract.HandlingFee')}}</div>
                        <div class="value">{{curMinute.service_fee_rate}}%</div>
                    </div>
                    <div class="info-item">
                        <div class="label">{{$t('SecondContract.Transfer')}}</div>
                        <div class="value">{{actualAmount}}USDT</div>
                    </div>
                    <!-- <div class="info-item">
                        <div class="label">{{$t('SecondContract.Profitability')}}</div>
                        <div class="value">{{curMinute.profit_rate}}%</div>
                    </div> -->
                </div>
                <div v-if="buyType == 1" class="buyup buy-btn" @click="buy(1)">{{$t('SecondContract.BuyUp')}}</div>
                <div v-else class="buydown buy-btn" @click="buy(-1)">{{$t('SecondContract.BuyDown')}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                showModal: false,
                typeList: [this.$t('PerpetualContract.Market'), this.$t('SecondContract.Scheduled')],
                typeIndex: 0,
                timer: null,
                curTime: '',
                date: '',
                dateOptions: {
                    start: '00:00',
                    step: '00:01',
                    end: '23:59'
                },
                curYmd: '',
                buyType: 1,
                openList: [],
                curIndex1: 0,
                timeList: [],
                curIndex2: 0,
                num: '',
                curMinute: { 
                    minute: 0,
                    profit_rate: 0,
                    service_fee_rate: 0
                },
                balance: 0
            }
        },
        created() {
            this.getCurTime()
            this.getBalance()
            this.getSetting()
        },
        destroyed() {
            clearInterval(this.timer)
        },
        computed: {
            curCoin() {
                return this.$store.state.curCoin
            },
            
            actualAmount() {
                let num = this.num ? parseFloat(this.num) : 0
                let service_fee = this.curMinute.service_fee_rate ? parseFloat(this.curMinute.service_fee_rate) / 100 : 0
                return (num * (1 - service_fee)).toFixed(2)
            }
        },
        watch: {
            typeIndex(val) {
                this.date = ''
                if(val == 1) {
                    let cur_time = this.curTime
                    let min_date = new Date(cur_time + 60 * 1000).format("yyyy-MM-dd hh:mm")
                    let max_date = new Date(cur_time + 60 * 60 * 1000).format("yyyy-MM-dd hh:mm")
                    let min_time = min_date.split(' ')[1]
                    let max_time = max_date.split(' ')[1]
                    if(Number(max_time.slice(0,2)) < Number(min_time.slice(0,2))) {
                        max_time = '23:59'
                    }
                    // this.date = min_time
                    this.dateOptions.start = min_time
                    this.dateOptions.end = max_time
                    this.curYmd = min_date.split(' ')[0]
                }
            }
        },
        methods: {
            changeTypeIndex(index) {
                this.typeIndex = index
            },
            
            getCurTime() {
                this.$api.setting({
                    slug: 'time'
                }).then(res => {
                    this.curTime = Date.parse(res.data.replace(/-/g, '/'))
                    this.$store.commit('setLocalTime',this.curTime)
                    this.timer = setInterval(() => {
                        this.curTime += 1000
                        this.$store.commit('setLocalTime',this.curTime)
                    },1000)
                })
            },
            
            getBalance() {
                this.$api.credit().then(res => {
                    res.data.forEach(item => {
                        if(item.type == 'USDT') {
                            this.balance = parseFloat(item.num)
                        }
                    })
                })
            },
            
            getSetting() {
                this.$api.setting({
                    slug: 'second_conf,second_moneys'
                },300000).then(res => {
                    this.openList = res.data.second_moneys ? res.data.second_moneys.split(',') : []
                    this.num = this.openList[this.curIndex1] || ''
                    this.timeList = res.data.second_conf ? JSON.parse(res.data.second_conf) : [],
                    this.curMinute = this.timeList[this.curIndex2] || { minute: 0, profit_rate: 0, service_fee_rate: 0}
                })
            },
            
            openForm(type) {
                this.buyType = type
                this.showModal = true
            },
            
            changeCurIndex1(index) {
                this.curIndex1 = index
                this.num = this.openList[this.curIndex1]
            },
            
            changeCurIndex2(index) {
                this.curIndex2 = index
                this.curMinute = this.timeList[this.curIndex2]
            },
            
            buy(direction) {
                this.$confirm(this.$t('Trade.BuyTips'), '', {
                    customClass: "prompt-box-h5",
                    confirmButtonText: this.$t('Common.Confirm'),
                    cancelButtonText: this.$t('Common.Cancel'),
                    confirmButtonClass: 'prompt-confirm-btn',
                    cancelButtonClass: 'prompt-cancel-btn',
                    showClose: false,
                    closeOnClickModal: false,
                    type: 'warning'
                }).then(() => {
                    let loading = this.$loading.service({
                        target: '#formWrap',
                        text: this.$t('Common.Loading')
                    })
                    if(this.typeIndex == 0) {
                        this.$api.secondOrderCreate({
                            product_id: this.curCoin.id,
                            direction,
                            minute: this.curMinute.minute,
                            money: this.num
                        }).then(res => {
                            loading.close()
                            this.$message({
                                message: res.message,
                                type: 'success',
                                duration: 1500
                            })
                            this.showModal = false
                            this.getBalance()
                            this.$bus.$emit('updateSecondOrder',0)
                        }).catch(err => {
                            loading.close()
                        })
                    } else {
                        if(!this.date) {
                            this.$message({
                                message: this.$t('SecondContract.ChooseTime'),
                                type: 'warning',
                                duration: 2000
                            })
                            loading.close()
                            return
                        }
                        let close_at = this.curYmd + ' ' + this.date + ':00'
                        this.$api.createPedding({
                            product_id: this.curCoin.id,
                            direction,
                            minute: this.curMinute.minute,
                            money: this.num,
                            close_at
                        }).then(res => {
                            loading.close()
                            this.$message({
                                message: res.message,
                                type: 'success',
                                duration: 1500
                            })
                            this.showModal = false
                            this.getBalance()
                            this.$bus.$emit('updateSecondOrder',2)
                        }).catch(err => {
                            loading.close()
                        })
                    }
                }).catch(() => {
                    
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .form-wrap {
        position: fixed;
        bottom: 6rem;
        left: 0;
        z-index: 20;
        width: 100%;
        
        .operate-btns {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 7rem;
            box-sizing: border-box;
            padding: 0 1.2rem;
            border-bottom: 1px solid #E9E9E9;
            background-color: #FFFFFF;
            
            .btn {
                width: 48%;
                height: 4.3rem;
                line-height: 4.3rem;
                box-sizing: border-box;
                border-radius: 2px;
                font-size: 1.3rem;
                color: #FFFFFF;
                text-align: center;
                cursor: pointer;
            }
            
            .buyup {
                background-color: #01BD8B;
            }
            
            .buydown {
                background-color: #FB474E;
            }
        }
        
        .mask {
            position: fixed;
            bottom: 6rem;
            left: 0;
            z-index: 40;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            width: 100%;
            height: calc(100vh - 6rem);
            background-color: rgba(0,0,0,.4);
            
            .box {
                width: 100%;
                max-height: 100%;
                box-sizing: border-box;
                padding: 1.6rem 1.6rem 2rem;
                border-bottom: 1px solid #E9E9E9;
                background-color: #FFFFFF;
                overflow: auto;
                animation-duration: 500ms;
            
                .title {
                    width: 100%;
                    margin-bottom: 2rem;
                    font-size: 1.6rem;
                    font-weight: bold;
                    color: #000000;
                    text-align: center;
                    word-wrap: break-word;
                }
                
                .order-type {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    margin-bottom: 2rem;
                            
                    .label {
                        margin-right: 1.6rem;
                        font-size: 1.2rem;
                        color: #383942;
                        white-space: nowrap;
                    }
                            
                    .type-list {
                        display: flex;
                        align-items: center;
                        max-width: 100%;
                            
                        .type-item {
                            height: 2.8rem;
                            line-height: 2.8rem;
                            box-sizing: border-box;
                            padding: 0 1.2rem;
                            border: 1px solid #F08834;
                            border-radius: 4px;
                            font-size: 1.2rem;
                            color: #F08834;
                            text-align: center;
                            background-color: #FFFFFF;
                            cursor: pointer;
                            
                            &:not(:last-child) {
                                margin-right: .8rem;
                            }
                        }
                            
                        .type-item-active {
                            color: #FFFFFF !important;
                            background-color: #F08834 !important;
                        }
                    }
                }
                
                .date-item {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    margin-bottom: 2rem;
                            
                    .label {
                        margin-right: 1.6rem;
                        font-size: 1.2rem;
                        color: #383942;
                        white-space: nowrap;
                    }
                    
                    /deep/ .el-date-editor {
                        width: 100%;
                    }
                }
                
                .operate-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    height: 4.5rem;
                    box-sizing: border-box;
                    padding: 0 1.2rem;
                    border: 1px solid #E7E9F1;
                    border-radius: 6px;
                    margin-bottom: 1.4rem;
                                    
                    .label {
                        margin-right: 2rem;
                        font-size: 1.2rem;
                        color: #383942;
                        white-space: nowrap;
                    }
                                    
                    .num {
                        max-width: 100%;
                        font-size: 1.4rem;
                        color: #000000;
                        word-break: break-all;
                    }
                    
                    .quantity-input {
                        width: 100%;
                                    
                        /deep/ .el-input__inner {
                            height: 3.6rem;
                            line-height: 3.6rem;
                            padding: 0;
                            border: 0;
                            font-size: 1.4rem;
                            color: #000000;
                            text-align: right;
                        }
                    }
                }
                
                .opening-options {
                    width: 100%;
                    margin-bottom: 2rem;
                    
                    .opening-quantity {
                        display: flex;
                        justify-content: center;
                        width: 100%;
                        margin-bottom: .6rem;
                        
                        .label {
                            min-width: 9rem;
                            max-width: 9rem;
                            margin: .8rem .8rem 0 0;
                            font-size: 1.2rem;
                            color: #383942;
                            word-wrap: break-word;
                        }
                        
                        .select-box {
                            width: 100%;
                            
                            .list {
                                display: flex;
                                flex-wrap: wrap;
                                width: 100%;
                            
                                .item {
                                    min-width: 5rem;
                                    max-width: 5rem;
                                    height: 2.8rem;
                                    line-height: 2.8rem;
                                    box-sizing: border-box;
                                    margin: 0 8px 8px 0;
                                    border: 1px solid #F08834;
                                    border-radius: 4px;
                                    font-size: 1.2rem;
                                    color: #F08834;
                                    text-align: center;
                                    background-color: #FFFFFF;
                                    cursor: pointer;
                                }
                            
                                .active {
                                    color: #FFFFFF !important;
                                    background-color: #F08834 !important;
                                }
                            }
                        }
                    }
                    
                    .opening-time {
                        width: 100%;
                        
                        .label {
                            width: 100%;
                            margin-bottom: 1.2rem;
                            font-size: 1.2rem;
                            color: #383942;
                            word-wrap: break-word;
                        }
                        
                        .list {
                            display: flex;
                            flex-wrap: wrap;
                            width: 100%;
                        
                            .item {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                width: 32%;
                                box-sizing: border-box;
                                padding: .8rem;
                                margin-left: 2%;
                                border-radius: 6px;
                                background-color: #F1F1F1;
                                cursor: pointer;
                                
                                &:nth-child(3n+1) {
                                    margin-left: 0;
                                }
                                
                                &:nth-child(n+4) {
                                    margin-top: .8rem;
                                }
                                
                                .time {
                                    max-width: 100%;
                                    margin-bottom: .3rem;
                                    font-size: 1.4rem;
                                    font-weight: bold;
                                    color: #000000;
                                    text-align: center;
                                    word-break: break-all;
                                }
                                
                                .profitability {
                                    max-width: 100%;
                                    font-size: 1.2rem;
                                    color: #6D6F7E;
                                    text-align: center;
                                    word-wrap: break-word;
                                }
                            }
                        
                            .active {
                                background-color: #F08834 !important;
                                
                                .time {
                                    color: #FFFFFF !important;
                                }
                                
                                .profitability {
                                    color: #FFFFFF !important;
                                }
                            }
                        }
                    }
                }
                
                .info-list {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    margin-bottom: 2rem;
                    
                    .info-item {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        box-sizing: border-box;
                        padding: 0 .5rem;
                        cursor: default;
                        
                        .label {
                            max-width: 100%;
                            margin-bottom: .8rem;
                            font-size: 1.2rem;
                            color: #383942;
                            text-align: center;
                            word-wrap: break-word;
                        }
                        
                        .value {
                            max-width: 100%;
                            font-size: 1.3rem;
                            color: #000000;
                            text-align: center;
                            word-break: break-all;
                        }
                    }
                }
                
                .buy-btn {
                    width: 100%;
                    height: 4.5rem;
                    line-height: 4.5rem;
                    box-sizing: border-box;
                    border-radius: 2px;
                    font-size: 1.3rem;
                    color: #FFFFFF;
                    text-align: center;
                    cursor: pointer;
                }
                
                .buyup {
                    background-color: #01BD8B;
                }
                
                .buydown {
                    background-color: #FB474E;
                }
            }
        }
    }
</style>